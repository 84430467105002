import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Customer Management">
    <section>
      <h3>Supercharge your customer experience.</h3>
      <p>The most important directive for a retailer is knowing their customer. Get to know your audience and supercharge your customer experience through
        digital technology transformations delivered by Quantic.</p>

      <h3>The customer is king.</h3>
      <p>The center of the digital revolution transforming the retail industry is the customer. The customer is king. Meet the rapidly evolving needs of your
        customer with Quantic’s omni-channel solutions designed to provide a seamless customer experience and enable a single customer view across all
        channels.</p>


      <div>TECHNOLOGY EXPERTISE</div>
      <ul>
        <li>Retail Customer Engagement Cloud Service (Relate)</li>
        <li>Retail Siebel CRM</li>
      </ul>

      <h3>Jump start your omni-channel initiative. Loyalty first.</h3>
      <p>No need to start from scratch. Quantic can integrate your existing point of sale system with a loyalty program, such as Retail Customer Engagement
        Cloud Service (RCE), and your existing e-commerce platform to jump start a seamless, omni-channel experience for your customers.</p>
    </section>
  </Layout>
);

export default Page;
